import axios from 'axios'
import { differenceInCalendarDays } from 'date-fns'
import { observable, decorate } from 'mobx';
import { getApiUrl } from '../helpers/utils'
import { Day, DayInterface } from './day'
import { TripNote } from './tripNote'

export type TripInterface = {
  id: number
  title: string
  guests: number
  startAt: Date
  endAt: Date
  price: number
  editable: boolean
  preview: boolean
  days: DayInterface[]
}

export class Trip {
  id: number
  title: string;
  guests: number
  startAt: Date;
  endAt: Date;
  price: number;
  editable: boolean;
  preview: boolean;
  notes?: Day;
  readonly days = observable<Day>([])

  constructor(trip: TripInterface) {
    this.id = trip.id
    this.title = trip.title
    this.guests = trip.guests
    this.startAt = trip.startAt
    this.endAt = trip.endAt
    this.price = trip.price
    this.editable = trip.editable
    this.preview = trip.preview
    if (trip.days?.[0]?.title === "Trip Notes") {
      let tripNotes = trip.days.shift()
      if (tripNotes) this.notes = new TripNote(tripNotes, this)
    }
    if (trip.days) {
      this.days.replace(trip.days.map((day) => new Day(day, this)))
    }
  }

  // We often want to replace the days after updating items so that we stay
  // sync'd with the backend (helps when updating date/time results in reordering
  // within a day or across days). By replacing the items of every day and not
  // the day itself, we maintain correct day references (e.g. selectedDay).
  replaceDays(days: DayInterface[]) {
    if (days[0]?.title === "Trip Notes") {
      let notes = days.shift()
      notes && this.notes?.replaceItems(notes.items)
    } else {
      this.notes = undefined
    }

    this.days.forEach((day, index) => {
      day.replaceItems(days[index].items)
    })
  }

  reorderDays(sourceIndex: number, destinationIndex: number) {
    // Perform optimistic update.
    let newDays = this.days.slice()
    newDays.splice(sourceIndex, 1)
    newDays.splice(destinationIndex, 0, this.days[sourceIndex])
    this.days.replace(newDays)

    // Perform real update.
    return axios
      .patch(getApiUrl(Trip.apiPath, this.id, 'reorder'), {
        sourceIndex,
        destinationIndex,
      })
      .then()
  }

  removeDay(day: Day) {
    return axios
      .delete(getApiUrl(Day.apiPath, day.id))
      .then((res: { data: { tripDays: DayInterface[] } }) => {
        this.days.remove(day)
        this.replaceDays(res.data.tripDays)
      })
  }

  static build(trip: TripInterface) {
    return new Trip(trip)
  }

  static apiPath = 'trips'

  get fullId() {
    return this.id
  }

  changeDates([start, end]: any) {
    // Perform optimistic update.
    if (start) this.startAt = start
    if (end) this.endAt = end

    // Perform real update.
    return axios
      .patch(getApiUrl(Trip.apiPath, this.id), {
        startAt: this.startAt,
        endAt: this.endAt,
      })
      .then((res: { data: TripInterface }) => {
        const newDays = res.data.days.slice(this.days.length)
        newDays.forEach((day) => this.days.push(new Day(day, this)))
      })
  }

  numberOfDays() {
    return differenceInCalendarDays(this.endAt, this.startAt) + 1
  }

  get daysOfTrip() {
    return this.days.slice(0, this.numberOfDays())
  }

  get daysWithNote() {
    let days = this.daysOfTrip
    if (this.notes) { days.unshift(this.notes) }
    return days
  }
}

decorate(Trip, {
  title: observable,
  startAt: observable,
  endAt: observable,
  price: observable,
});
