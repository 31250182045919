import React, { FC } from 'react'
import { getMonthAndDay } from '../../helpers/utils'
import { Day } from '../../types/day'
import { PublicItem } from './PublicItem'

// Note: We take special care to avoid page breaks for our day title AND the
// first item. This way we'll always show at least one item under a day's title.
export const PublicDay: FC<{
  day: Day;
  priColor: string,
  secColor: string,
  pageBreaks: boolean,
  fontSize: string
}> = ({
  day,
  priColor,
  secColor,
  pageBreaks,
  fontSize
}) => {
    return (
      <div id={`day-${day.position}`} className={`space-y-8 text-${fontSize}`}>
        <div className="space-y-4 break-inside-avoid" style={pageBreaks ? { breakBefore: "page" } : {}}>
          <div className="sm:hidden">
            <div className="pt-1 bg-gradient-to-r from-[var(--primary-color)] to-[var(--secondary-color)]"></div>
            <div className="mt-4 text-5xl uppercase font-pdf-heading tracking-normal mx-0 sm:mx-4 md:mx-6 leading-tight text-center w-full bg-clip-text text-transparent bg-gradient-to-r from-[var(--primary-color)] to-[var(--secondary-color)]">
              {!day.isNote && <div className="text-[9vw]">{getMonthAndDay(day.date)}</div>}
              <div className="text-[9vw]">{day.title}</div>
            </div>
          </div>

          <div className="hidden sm:flex" style={{
            border: "10px solid",
            borderImageSlice: 1,
            borderWidth: "2px",
            borderImageSource: `linear-gradient(to right,${priColor}, ${secColor})`,
            justifyContent: "center",
          }}>
            <p className="mx-6 text-5xl uppercase font-pdf-heading tracking-wider leading-tight" style={{
              flexShrink: 1,
              fontSize: "clamp(1rem, 6vw, 3rem)",
              backgroundColor: `${priColor}`,
              backgroundImage: `linear-gradient(to right,${priColor}, ${secColor})`,
              backgroundSize: "100%",
              WebkitBackgroundClip: "text",
              MozBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}>{!day.isNote && <>{getMonthAndDay(day.date)}&nbsp;|&nbsp;</>}{day.title}</p>
          </div>

          {/*Show first item within break-inside-avoid with day's title.*/}
          {day.items.length > 0 && (
            <PublicItem
              item={day.items[0]}
              date={day.date}
              key={day.items[0].id}
              priColor={priColor}
              secColor={secColor}
            />
          )}
        </div>

        {/*Show the rest of the items.*/}
        {day.items.length > 1 && (
          <div className="space-y-8">
            {day.items.slice(1).map((item) => (
              <PublicItem
                item={item}
                date={day.date}
                key={item.id}
                priColor={priColor}
                secColor={secColor}
              />
            ))}
          </div>
        )}
      </div>
    )
  }
